import React from 'react';
import { Link } from 'gatsby';
import { formatDate } from './../../util';

interface LinkCardProps {
  linkPath: string;
  image: string;
  title: string;
  date?: Date;
  children?: React.ReactNode;
}

const LinkCard: React.FC<LinkCardProps> = props => {
  return (
    <Link to={props.linkPath}>
      <div className="flex flex-col h-auto overflow-hidden bg-white rounded-lg shadow sm:flex-row">
        <div className="overflow-hidden max-h-48 sm:w-1/3 md:w-1/4">
          <img className="object-cover w-full h-48" src={props.image} />
        </div>
        <div className="w-full p-6 pt-0 sm:w-2/3 md:w-3/4">
          <p className="mt-10 text-xl font-bold text-primary sans-serif">
            {props.title}
          </p>{' '}
          {!!props.date && (
            <p className="text-gray-500 text">
              {formatDate(new Date(props.date), true)}
            </p>
          )}
          <div>{props.children}</div>
        </div>
      </div>
    </Link>
  );
};

export default LinkCard;
