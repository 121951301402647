import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import ContentContainer from '../components/common/ContentContainer';
import Layout from '../components/Layout';
import SectionHeader from '../components/SectionHeader';
import LinkCard from './../components/common/LinkCard';
import SEO from '../components/Seo';

type NewsItem = {
  id: string;
  title: string;
  createdAt: string;
  body: any;
  image: string;
  slug: string;
  featuredImage: {
    fluid: {
      src: string;
    };
  };
  publishedDate: Date;
};

interface NewsCardProps {
  newsItem: NewsItem;
  newsFallbackImage: any;
}

const NewsCard: React.FC<NewsCardProps> = props => {
  return (
    <LinkCard
      linkPath={`/news/${props.newsItem.slug}`}
      image={
        props.newsItem.featuredImage?.fluid?.src ??
        props.newsFallbackImage.childImageSharp.sizes.src
      }
      title={props.newsItem.title}
      date={props.newsItem.publishedDate}
    />
  );
};

const News: React.FC = () => {
  const data = useStaticQuery(graphql`
    query NewsItemsQuery {
      newsFallbackImage: file(
        relativePath: { eq: "images/news-fallback.png" }
      ) {
        childImageSharp {
          sizes(maxWidth: 300) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      allContentfulNewsItem(
        filter: { node_locale: { eq: "en-US" } }
        sort: { order: DESC, fields: publishedDate }
      ) {
        edges {
          node {
            id
            body {
              body
            }
            title
            createdAt
            node_locale
            slug
            publishedDate
          }
        }
      }
    }
  `);

  const newsItems = data.allContentfulNewsItem.edges.map(
    (newsItem: any) => newsItem.node
  );

  return (
    <Layout>
      <SEO title="News" />
      <ContentContainer>
        <SectionHeader header="News" />
        <div className="flex mt-8">
          <div className="w-full flex flex-col">
            {newsItems.map((newsItem: NewsItem) => (
              <div className="mb-4" key={newsItem.id}>
                <NewsCard
                  newsItem={newsItem}
                  key={newsItem.id}
                  newsFallbackImage={data.newsFallbackImage}
                />
              </div>
            ))}
          </div>
        </div>
      </ContentContainer>
    </Layout>
  );
};

export default News;
